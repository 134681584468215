import { UserRole } from './enums';
import { JwtPermission } from '../helpers/jwt-permissions.transformer';

export const emptyPermissionMock: JwtPermission = {
  companies: {},
  global: [],
};

export const companyMemberPermissionMock: JwtPermission = {
  companies: {
    '61af181a95e82835ee7d6f78': [UserRole.USER_COMPANY],
  },
  global: [
    UserRole.USER_GAMESTORE,
    UserRole.USER_WALLET,
    UserRole.USER,
    UserRole.ADMIN_GDC,
    UserRole.USER_MARKETPLACE,
    UserRole.ADMIN_ULTRA,
    UserRole.ADMIN_MARKETPLACE,
  ],
};

export const noCompanyMemberPermissionMock: JwtPermission = {
  companies: {},
  global: [UserRole.USER_GAMESTORE],
};

export const noAdminPermissionMock: JwtPermission = {
  companies: {},
  global: [UserRole.USER_WALLET, UserRole.USER_MARKETPLACE, UserRole.USER_GAMESTORE],
};

export const adminPermissionMock: JwtPermission = {
  companies: {
    '61af181a95e82835ee7d6f78': [UserRole.USER_COMPANY],
  },
  global: [
    UserRole.USER_GAMESTORE,
    UserRole.USER_WALLET,
    UserRole.USER,
    UserRole.ADMIN_GDC,
    UserRole.ADMIN_WALLET,
    UserRole.USER_MARKETPLACE,
    UserRole.ADMIN_ULTRA,
    UserRole.ADMIN_MARKETPLACE,
  ],
};
