import { CompanyPermission, JwtPermission } from '../helpers/jwt-permissions.transformer';

import { UserRole, UserRoleMap } from './enums';
import { IUserRoles } from './interfaces';

export const defaultRoles: IUserRoles = {
  user: false,
  ultraAdmin: false,
  companyMember: false,
  walletUser: false,
  walletAdmin: false,
  marketUser: false,
  marketAdmin: false,
  gameStoreUser: false,
  gdcAdmin: false,
};

export const isCompanyOwner = (role: string) => role === UserRole.OWNER_COMPANY;
export const isCompanyAdmin = (role: string) => role === UserRole.ADMIN_COMPANY;
export const isCompanyUser = (role: string) => role === UserRole.USER_COMPANY;
export const isWalletUser = (role: string) => role === UserRole.USER_WALLET;
export const isWalletAdmin = (role: string) => role === UserRole.ADMIN_WALLET;

/**
 * Given an array of roles, check if there is at least one with code 100C, 200C or 300C.
 */
export const hasCompanyRole = (roles: string[]): boolean => {
  return roles.some((role: string) => isCompanyOwner(role) || isCompanyAdmin(role) || isCompanyUser(role));
};

/**
 * Given an array of roles, check if there is at least one with code 100C or 300C.
 */
export const hasCompanyEditRole = (roles: string[]): boolean => {
  return roles.some((role: string) => isCompanyOwner(role) || isCompanyAdmin(role));
};

/**
 * Given an array of roles, check if there is at least one with code 001W or 999W.
 */
export const hasWalletRole = (roles: string[]): boolean => {
  return roles.some((role: string) => isWalletUser(role) || isWalletAdmin(role));
};

/**
 * Check if there are at least one company with
 * company role.
 */
export const isCompanyMember = (companies: CompanyPermission) =>
  companies &&
  Object.keys(companies).length > 0 &&
  Object.values(companies).some((roles: string[]) => hasCompanyRole(roles));

/**
 * Given a permission object of global roles and companies
 * returns the correspondent object of authorized roles.
 */
export const getUserRoles = (permissions: JwtPermission): IUserRoles => {
  const roles = defaultRoles;

  if (!permissions || !permissions.global) {
    return roles;
  }

  const globalPermissions = permissions.global.reduce((acc, permission) => {
    return { ...acc, [permission]: true };
  }, {});

  const rolesNames = Object.keys(roles);

  rolesNames.forEach((roleNameKey) => {
    roles[roleNameKey] = !!globalPermissions[UserRoleMap[roleNameKey]];
  });

  roles.companyMember = isCompanyMember(permissions.companies);
  return roles;
};
