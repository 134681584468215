import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { JwtPermission } from '../helpers/jwt-permissions.transformer';
import { UserFacadeService } from '../stores/user';

import { IUserRoles } from './interfaces';
import { UserResourceAccess } from './interfaces';
import { getUserRoles } from './permission.helper';

@Injectable({ providedIn: 'root' })
export class PermissionService {
  private userFacadeService = inject(UserFacadeService);

  userRoles$: Observable<IUserRoles> = this.userFacadeService.user$.pipe(
    map((user) => user.permission),
    filter(Boolean),
    map((permissions: JwtPermission) => getUserRoles(permissions)),
  );
  userPermissions$: Observable<JwtPermission> = this.userFacadeService.user$.pipe(map((user) => user.permission));
  userResourceAccess$: Observable<UserResourceAccess> = this.userFacadeService.user$.pipe(
    map((user) => user.resourceAccess),
    filter(Boolean),
  );
}
